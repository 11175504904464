import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  useTheme,
  Grid,
  Box,
  Checkbox,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch, useSelector } from "react-redux";
import { setLock, setPage } from "src/store/reducers/registerReport";
import ProgressBar from "src/layouts/Report/ProgressBar";
import logo from '../../../assets/logo/Logo (1).png';
import policeImage from '../../../assets/images/police.png';

// Import statements...

const Page17 = () => {
  const { data } = useSelector((state) => state.reportRegister);
  const dispatch = useDispatch();
  const theme = useTheme();
const {ptc,ptc_value}=data;

if(ptc_value == null){
  dispatch(setPage({ ptc_value: [] }));
}
if(typeof ptc_value==='string'){
  const array = ptc_value.split(",").map(Number);
  dispatch(setPage({ ptc_value:  array}));
}
  

  

  useEffect(() => {
    // Dispatch the updated values to the Redux store
    // console.log(data.ptc)
    dispatch(
      setPage({
        ptc,
        ptc_value,
      })
    );
  }, [ptc, ptc_value]);

  const handleCheckboxChange = (value) => {
    //setptc(value);
    dispatch(setPage({ ptc: value }));
    // Reset the state of ptc_value when "No" is selected
    if (value === 0) {
      //setptc_value([]);
      dispatch(setPage({ ptc_value: [] }));
    }
  };

  const handleLawEnforcementChange = (event) => {
    const newValue = 0;
    if (event.target.checked) {
      //setptc_value([...ptc_value, newValue]);
      dispatch(setPage({ ptc_value: [...ptc_value, newValue] }));
    } else {
      //setptc_value(ptc_value.filter((value) => value !== newValue));
      dispatch(setPage({ ptc_value: ptc_value.filter((value) => value !== newValue) }));
    }
  };
  
  const handleAntiCrimeAgencyChange = (event) => {
    const newValue = 1;
    if (event.target.checked) {
      //setptc_value([...ptc_value, newValue]);
      dispatch(setPage({ ptc_value: [...ptc_value, newValue] }));
    } else {
      //setptc_value(ptc_value.filter((value) => value !== newValue));
      dispatch(setPage({ ptc_value: ptc_value.filter((value) => value !== newValue) }));
    }
  };
  
  useEffect(() => {
    const userProfile = localStorage.getItem('profile');
    if (userProfile) {
      const profileData = JSON.parse(userProfile);
      
      if (profileData.data.id === data.user_id) {
        // Set default state here if user_id matches
        //setptc(0);
        // dispatch(setPage({ ptc: 0 }));
        //setptc_value([]);
        // dispatch(setPage({ ptc_value: [] }));
      }
    }
  }, []);
// console.log(ptc_value)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ paddingY: 0 }}
          mt={5}
        >
          <Grid item xs={10}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                borderBottom={2}
                borderColor={theme.palette.warning.main}
                style={{ marginRight: "5px", width: "20px" }}
              />
              <Typography
                variant="h1"
                align="center"
                style={{
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  fontSize: "24px",
                }}
              >
                Permission to Contact
              </Typography>
              <Box
                borderBottom={2}
                borderColor={theme.palette.warning.main}
                style={{ marginLeft: "5px", width: "20px" }}
              />
            </Box>
          </Grid>

          <Grid item xs={8} style={{ paddingLeft: "40px" }}>
            <Typography
              id="number-picker-label"
              style={{
                paddingTop: "40px",
                paddingBottom: "16px",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Are you willing to be contacted by law enforcement or anti-crime agencies in relation to this crime?
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center" paddingTop={"20px"} paddingBottom={"20px"}>
            <Box>
  <Checkbox
    checked={ptc === 1}
    onChange={() => handleCheckboxChange(1)}
  />
  <Typography style={{ paddingLeft: "8px" }}>Yes</Typography>
</Box>
<Box marginLeft={2}>
  <Checkbox
    checked={ptc === 0}
    onChange={() => handleCheckboxChange(0)}
  />
  <Typography style={{ paddingLeft: "8px" }}>No</Typography>
</Box>
</Box>

            

            {true && (
              <div>
                <Typography
                  id="select-boxes-label"
                  style={{
                    paddingTop: "36px",
                    paddingBottom: "36px",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  If yes, select all boxes that apply:
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  paddingLeft={"20%"}
                  paddingBottom={"20px"}
                >
                  <img src={policeImage} alt="Police" style={{ marginRight: "8px",height: "40px" }} />
                  <Checkbox
                    checked={ptc_value.includes(0)}
                    onChange={handleLawEnforcementChange}
                    disabled={ptc === 0} // Disable if ptc is 0
                  />
                  <Typography style={{ paddingLeft: "8px" }}>
                    Law Enforcement
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  paddingLeft={"20%"}
                >
                   <img src={logo} alt="Police" style={{ marginRight: "8px",height: "32px" }} />
                  <Checkbox
                  sx={{marginBottom:"12px"}}
                    checked={ptc_value.includes(1)}
                    onChange={handleAntiCrimeAgencyChange}
                    disabled={ptc === 0} // Disable if ptc is 0
                  />
                  <Typography style={{ paddingLeft: "8px", marginTop:"10px" }}>
                    Anti-Crime Agency Partners <br/><span style={{fontSize: "13px"}}>i.e. Private Security</span>
                  </Typography>
                </Box>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default Page17;
