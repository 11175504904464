import React, { useState, useEffect } from 'react';
import { Container,useTheme,  Typography, Grid, Box, Divider, LinearProgress, Checkbox, Select, MenuItem } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import NextButton from 'src/components/Button/NextButton';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from 'src/store/reducers/registerReport';
import { loadGoogleMaps } from 'src/utils/googleMap';
import ProgressBar from 'src/layouts/Report/ProgressBar';
import  API from "src/config/api";

function Page10() {
  const {burglary:checked,burglary_type:value=[]} = useSelector(state=>state.reportRegister.data);
  const dispatch = useDispatch();
  const theme = useTheme();
  const setChecked=(burglary)=>dispatch(setPage({burglary}));
  const setValue=(burglary_type)=>dispatch(setPage({burglary_type}));
  const [burglarydata, setBurglary] = useState([]);
  // let newvalue;
 
  if(value === null) {
    dispatch(setPage({burglary_type: []}));
  }
  if(typeof value === 'string') {
  //  console.log(value)
    const decodedValue = value.split(',');
    dispatch(setPage({burglary_type:decodedValue}));
    //  console.log(decodedValue);
  }
  

  


//   if (typeof value === 'string') {
//     // Remove surrounding double quotes if present
//     newvalue = value.replace(/^"(.*)"$/, '$1');
//     // Convert the string to an array
//     newvalue = newvalue.split(',');
// }
const [selectedValues, setSelectedValues] = useState(value);

  const handleChange = (value) => {
    setChecked(value);
  };
  

  const handleToggle = (value) => () => {
      const currentIndex = selectedValues?.indexOf(value);
      const newSelected = selectedValues!==null ?[...selectedValues]:[];

      if (currentIndex === -1) {
          newSelected.push(value);
      } else {
          newSelected.splice(currentIndex, 1);
      }

      setSelectedValues(newSelected);
      setValue(newSelected);
  };



  useEffect(() => {
    // dispatch(setPage({}));

    const fetchCarMakeInfo = async () => {
      const response = await API.get("/burglary");
      const result = response.data;
      setBurglary(result.data.map(data => data));
    }
    fetchCarMakeInfo();
    
  }, []);
  // console.log(value)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Container>
            <Grid container spacing={2} justifyContent="center" sx={{ paddingY: 0 }}>
              <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} mt={5}>
              <Box display="flex" alignItems="center" justifyContent="center">
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginRight: '5px', width: '20px' }} />
              <Typography variant="h4" align="center" style={{ fontWeight: 'bold', paddingBottom: '5px', fontSize: '24px' }}>
                  Burglary/Shoplifting
                </Typography>
                <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginLeft: '5px', width: '20px' }} />
                </Box>
                <Typography variant="h2" align="center" style={{ fontWeight: 'bold', paddingBottom: '20px', fontSize: '12px' }}>
                  (i.e. perpetrator(s) entered building)
                </Typography>
                <Typography variant="h2" align="center" style={{ fontWeight: 'bold', paddingBottom: '20px', fontSize: '12px', color: 'red' }}>
                  next page is Robbery, i.e. perpetrator(s) did not enter a building and the crime took place outdoors
                </Typography>
              </Grid>

              <Box sx={{ pl: 8, }}>
                <Typography id="number-picker-label" style={{ paddingBottom: '16px', textAlign: 'center', fontSize: '100%' }}>
                  Was burglary or shoplifting involved?
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
                    <Checkbox checked={checked==0} value={0} onChange={()=>handleChange(0)} />
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 5, textAlign: 'left' }}>
                      Does not apply
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
                    <Checkbox checked={checked==1} value={1} onChange={()=>handleChange(1)} />
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 5, textAlign: 'left' }}>
                      Attempted Burglary (not shoplifting)
                      <br />
                      <span sx={{ fontSize: 's' }}>goods were not stolen</span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
                    <Checkbox checked={checked==2} value={2} onChange={()=>handleChange(2)} />
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 5, textAlign: 'left' }}>
                      Burglary (not shoplifting)
                      <br />
                      <span sx={{ fontSize: 's' }}>goods were stolen</span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
                    <Checkbox checked={checked==3} value={3} onChange={()=>handleChange(3)} />
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 5, textAlign: 'left' }}>
                        Attempted Shoplifting
                      <br />
                      <span sx={{ fontSize: 'sm' }}>goods were not stolen</span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
                    <Checkbox checked={checked==4} value={4} onChange={()=>handleChange(4)} />
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 5, textAlign: 'left' }}>
                        Shoplifting
                      <br />
                      <span sx={{ fontSize: 'sm' }}>goods were stolen</span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', my: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 1, pt: 3, pb: 3, textAlign: { md: 'left' } }}>
                      Select stolen goods item from the list below
                    </Typography>
                    <Select
                multiple
                value={selectedValues && selectedValues[0] !== 'null' ? selectedValues : []}

                onChange={() => {}} // Disable default onChange behavior to handle selection via checkboxes
                sx={{ px: 2, width: '50%', borderRadius: 'none', height: '10' }}
                disabled={checked == 0 || checked == 1 || checked == 3}
                renderValue={(selected) =>  selected?.join(', ')}
            >
                {burglarydata.map((option) => (
                    <MenuItem key={option} value={option}>
                        <Checkbox
                            checked={selectedValues && selectedValues.indexOf(option) !== -1}
                            onChange={handleToggle(option)}
                        />
                        {option}
                    </MenuItem>
                ))}
            </Select>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Container>

          
    </LocalizationProvider>
  );
}

export default Page10;
