import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function FAQs() {
  return (
    <div>
      <Container sx={{ py: 6, textAlign: 'left' }}>
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
          CrimeChime FAQs
        </Typography>

        <Box sx={{ maxWidth: '1200px', margin: '0 auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.1</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>What is CrimeChime?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                CrimeChime is a crime reporting register and database allowing persons with RICA-registered South African cell-phone numbers to report crimes within South Africa.
              </Typography>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.2</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>What is RICA and how does it relate to SIM cards / cell phone numbers?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                The RICA Act is a law passed by the South African government requiring, inter alia, that electronic communication service providers (such as Vodacom and MTN) register and keep certain information pertaining to their clients. As such, cellphone numbers in South Africa are linked to passports or I.D. documents.
              </Typography>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.3</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>How do I use CrimeChime?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                To view reported crimes, simply browse either the “Crime Map” or “Crime Table” and filter the data as required. This is open to anyone.
                <br /><br />
                To report a crime, click the yellow “+” symbol and you will be asked to enter your South Africa cellphone number, after which you will be sent a one-time pin (OTP) via SMS. After entering the OTP, complete the onscreen steps to enter information about the crime you wish to report. This typically takes less than 5 minutes. Additional information can also be added after submission.
              </Typography>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.4</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Who owns CrimeChime?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                CrimeChime is owned by G-Chime Solutions Limited, a company incorporated in England and Wales, and which is wholly owned by Gemfields Group Limited (“Gemfields”). Gemfields is listed on the Johannesburg Stock Exchange (JSE:GML) and on the London Stock Exchange’s Alternate Investment Market (AIM:GEM).
              </Typography>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.5</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>How did CrimeChime come about?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                CrimeChime was originally conceived by Gemfields in 2019 as a corporate responsibility and community project. Gemfields operates gemstone mines in Mozambique and Zambia and the availability of information about crimes affecting communities is generally poor, as is the speed of the mechanisms by which communities can report crimes. This challenge gave rise to CrimeChime as a concept. With the arrival of Covid-19, the project was shelved. Work recommenced in 2022 and South Africa was identified as a prospective pilot country. Experience garnered in South Africa will guide the possible later roll-out in other countries.
              </Typography>
            </div>
          </div>

                    {/* FAQ 1.6 */}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.6</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Is there a fee or subscription to view or report crimes?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                It is free to use CrimeChime to view or report crimes.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.7 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.7</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Which countries does CrimeChime cover?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                At present, CrimeChime only allows crimes that occurred within the borders of the Republic of South Africa to be reported. Additionally, crimes can only be reported from South African RICA-registered SIM cards. However, any person worldwide can view the “Crime Map” and the “Crime Table” and perform searches and filtering. CrimeChime may in the future be expanded to other countries.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.8 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.8</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Will CrimeChime reveal or sell my phone number to anyone?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                All phone numbers are stored in encrypted format in the CrimeChime database. No user phone numbers are published on the CrimeChime platform. The only circumstances under which CrimeChime will reveal or provide a user’s phone number to a third party is if it: (i) is compelled to do so by applicable law or court order; or (ii) the user has granted their consent; or (iii) the user has deliberately reported false information or abused the CrimeChime system.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.9 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.9</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Which languages is CrimeChime available in?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                At present, CrimeChime is only available in English.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.10 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.10</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Which crime details does CrimeChime publish online?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                CrimeChime does not publish any information entered in free text fields (such as general descriptions of the crime or the perpetrators). CrimeChime also does not publish any photos or videos uploaded by users.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.11 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.11</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Can I edit crimes after I’ve submitted them?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                Yes. By logging into your account, you can modify your own entries or provide further information. You can also contribute to crimes submitted by other users if you have additional information or evidence. Note that free text submissions, pictures and videos are not made available to the public on CrimeChime.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.12 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.12</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Can I upload further photos / videos at a later stage?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                Yes, any user can contribute additional comments, photos or videos where they have knowledge or information about a crime. This information is not published on CrimeChime for all users to see.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.13 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.13</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>What is the difference between a burglary and a robbery?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                CrimeChime takes the following approach: (i) burglaries involve the perpetrator entering premises or a building; (ii) robberies occur outdoors or in public places.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.14 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.14</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Can law enforcement access the details I submit?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                Any member of the public (including law enforcement) is able to access key crime data in the “Crime Map” and “Crime Table”. However, “free text” information, photos and videos are not published and cannot be accessed by third parties. The only circumstances under which CrimeChime will grant third parties such access is if CrimeChime: (i) is compelled to do so by applicable law or court order; or (ii) the user has granted their consent.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.15 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.15</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Does CrimeChime cover vehicle accidents?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                No.
              </Typography>
            </div>
          </div>

          {/* FAQ 1.16 */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '1rem' }}>
            <div style={{ width: '30px', flexShrink: 0 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>1.16</Typography>
            </div>
            <div>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>Can CrimeChime provide notifications of new and nearby crimes?</Typography>
              <br/>
              <Typography variant="body2" sx={{ color: '#333', mb: 3, fontSize: "18px" }}>
                Yes. You can set up locations of interest (such as your home or office) and CrimeChime will then, subject to system volumes and capacity, send you an SMS or push-notification of crimes that have been reported as having occurred within the last 24 hours and within 1km of the locations you’ve specified.
              </Typography>
            </div>
          </div>


        </Box>
      </Container>
    </div>
  );
}

export default FAQs;
