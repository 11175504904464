import Home from 'src/pages/Frontend/Home'
import ViewReport from 'src/pages/Frontend/ViewReport';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import OtpVerify from '../pages/Auth/OtpVerify';
import ViewReportMap from 'src/pages/Frontend/ViewReport/ViewReportMap';
import ViewCrime from 'src/pages/Frontend/ViewCrime/ViewCrime';
import CrimeDetail from 'src/pages/Frontend/ViewCrime/crimeDetail';
import AboutUs from 'src/pages/Frontend/Home/AboutUs';
import Faq from 'src/pages/Frontend/Home/Faq';
import Tnc from 'src/pages/Frontend/Home/Tnc';
import PrivacyPolicy from 'src/pages/Frontend/Home/PrivacyPolicy';
import ContactUs from 'src/pages/Frontend/Home/ContactUs';
import FAQs from 'src/pages/Frontend/Home/FAQs';
const FrontendRoutes = [
    {
        path: '/',
        element: <ViewCrime />
    },
    {
        path: 'reportshome', 
        element: <ViewCrime />
    },
    {
        path: 'reportscrime', 
        element: <ViewCrime />
    },
    {
        path: 'crimedetails', 
        element: <CrimeDetail />
    },
    {
        path: '/report',
        element: <ViewReport />
    },
    {
        path: '/aboutus',
        element: <AboutUs />
    },
    {
        path: '/contactus',
        element: <ContactUs />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path:'/faq',
        element: <FAQs />
    },
    {
        path:'/termsandconditions',
        element: <Tnc />
    },
    {
        path:'/privacypolicy',
        element: <PrivacyPolicy />
    },
    {
        path: '/register',
        element: <Register />
    },
    {
        path: '/auth/verify',
        element: <OtpVerify />
    },
]


export default FrontendRoutes;