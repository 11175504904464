import React from 'react';
import { Container, Typography,Box } from '@mui/material';
import './pp_styles.css'
const PrivacyPolicy = () => {
  return (
    <div style={{  }}>
      <Container sx={{ py: 6, textAlign: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
            <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>Privacy Policy</Typography>
            
          </div>
        </div>
      </Container>
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Center content horizontally
        paddingX: { xs: '2%', sm: '15%' }, // Disable paddingX on mobile screens
        marginLeft: { xs: 0, sm: '5%' }, // Disable marginLeft on mobile screens
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: '18px',
          maxWidth: '4000px',
          color: '#333',
          mb: 4,
          lineHeight: 1.5,
          textAlign: 'justify',
          width: '100%', // Ensure typography takes full width within the Box
        }}
      >
      <>
      

      <p class=""><br/><span class="num">1.</span> <strong style={{marginLeft:'0px'}}>OBJECTIVE</strong></p><br/>

<div class="num2">
<p class="p1">The purpose of this privacy policy (Privacy Policy) is to outline what G-Chime Solutions Limited
(Registration number 14934937) (<strong>G-Chime</strong>) does with your personal data. It describes how G-Chime
collects, uses, and processes your personal data, and how, in doing so, G-Chime complies with its
legal obligations to you. Your privacy is important to G-Chime, and G-Chime is committed to
protecting and safeguarding your data privacy rights.</p>
</div>

<p class=""><br/><span class="num">2.</span> <strong style={{marginLeft:'0px'}}>SCOPE</strong></p><br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 2.1</div>
 

<div style={{paddingLeft:'36px'}}>
<span>As set out in G-Chime's Terms and Conditions, G-Chime provides an information service that allows users who register using a South African mobile phone to upload and review data
on reported criminal activity within a selected area and time period. The data is received by
G-Chime from users of the website (www.crimechime.co.za) or the CrimeChime mobile
phone or tablet application (collectively, &ldquo;<strong>the Service Channels</strong>&rdquo;) and this data is
consolidated so that users are able to report criminal activity as well as to visualise and
review criminal activity that has been reported by other users (<strong>Service</strong>).</span>
</div>
</div>

<br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 2.2</div>
 

<div style={{paddingLeft:'32px'}}>
<span>This Privacy Policy sets out the basis on which G-Chime will collect and process any personal data received from individuals who choose to utilise the Service, including the
personal data of other individual third parties which is provided by such individuals as part of
the Service. The types of personal data that G-Chime collects and processes includes mobile
phone numbers and any personal information that may be provided to G-Chime. The
personal data of individuals is subject to certain legal safeguards specified in South Africa&rsquo;s
Protection of Personal Information Act, 2013 (<strong>POPI Act</strong>) and the UK&rsquo;s General Data
Protection Regulation (<strong>UK GDPR</strong>).</span>
</div>
</div>
<br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 2.3</div>
 

<div style={{paddingLeft:'32px'}}>
<span>Personal data is any information or data relating to an individual, who is identified or can be identified, and includes both facts and opinions. G-Chime is the &quot;responsible party&quot; as
defined in the POPI Act and the &quot;controller&quot; as defined in the UK GDPR for all personal data
used in G-Chime&apos;s provision of the Service.</span>
</div>
</div>

<br/>
<div class="num2"><p class="p1"></p></div>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 2.4</div>
 

<div style={{paddingLeft:'32px'}}>
<span>This Privacy Policy replaces and supersedes any previous policies addressing the same or similar issues, whether formal or informal. G-Chime reserves the right in its absolute
discretion to alter, amend, or replace this Privacy Policy in whole or in part at any time with
or without notice. You should always check that you are referring to the latest version
 of this Privacy Policy if you have previously downloaded hard copies of this Privacy Policy.</span>
</div>
</div>
<div class="num2"><p class="p1">
</p></div>

<p class=""><br/><span class="num">3.</span> <strong style={{marginLeft:'0px'}}>POLICY</strong></p><br/>
<p class="p1"><span class="num">3.1</span> <strong style={{marginLeft:'0px'}}>Types of Personal Data Collected</strong></p><br/>
<div class="num2">
  <p class="p1">The information described below is in addition to any personal data that G-Chime is required
  by law to process.</p><br/>
  <p class="p1"><strong>Personal Information about individual users and third party individuals</strong></p><br/>
  <p class="p1">In order to supply the Service, G-Chime will collect some or all of the information listed below
  in relation to individuals who use the Service as well as third parties who may be referred to
  by users when using the Service.</p>
  <ul class="li">
    <li class="p1">Mobile Phone Number: Mobile phone number you provide when you register to use the Service so that you can receive a one-time pin which will then allow you access to the Service Channels.</li>
    <li class="p1">Any additional personal information: Information G-Chime collects when you upload your personal information or the personal information of third parties when reporting criminal activity using the Service Channels. G-Chime uses this information <em>inter alia</em> to distinguish verified users, to better understand its user base and to collect improved information relating to reported crimes.</li>
    <li class="p1">Website and Mobile Application connection information: Your IP address, type, version and language of Internet browser, operating system and version, type of device, screen resolution, referring and exit web pages. G-Chime uses your IP address to understand the geographic makeup of the users of the Service Channels and the Service and to assist with the identification of potential unauthorized access. G-Chime uses the rest of this information to optimize the usability of the Service on your access device. G-Chime may also compile aggregate information to analyze usage patterns and make improvements to our Service.</li>
  </ul>
  <p class="p1">NOTE: The above list of categories of personal data G-Chime may collect is not exhaustive.</p>
</div>
<br/><p class="p1"><span class="num">3.2</span> <strong >How Your Personal Data is Collected</strong></p><br/>
<div class="num2">
  <p class="p1">G-Chime collects users' personal data as well as personal data that users may include when
  reporting criminal activity on the Service Channels in two ways:</p>
  <ul class="li">
    <li class="p1">personal data that G-Chime receives directly from the user; and</li>
    <li class="p1">personal data that G-Chime collects automatically.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>3.2.1</span> <strong >Personal Data G-Chime Receives Directly from users</strong></p><br/>
<div class="num2">
  <p class="p1">G-Chime will receive personal data directly from a user where the user engages
  with any of the Service Channels and provides personal data (e.g. a South African
  mobile phone number) for the purposes of registration or inserts new information
  about criminal activity which may or may not include personal data of third parties.
  Just as you, as a user, may provide us with personal data of another individual
  when reporting a crime, other users may do the same in respect of you.</p>
</div>
<br/><p class="p1"><span  style={{marginRight:'14px'}}>3.2.2</span> <strong >Personal Data Collected Automatically</strong></p><br/>
<div class="num2">
  <p class="p1">When you visit G-Chime's website there is certain information that G-Chime may
  automatically collect, whether or not you decide to use the Services. This includes
  your IP address, the date and the times and frequency with which you access the
  website and the way you browse its content.
  G-Chime collects your data automatically via cookies, in line with your cookie
  settings.</p>
</div>


<br/><p class=""><br/><span class="num">4.</span> <strong style={{marginLeft:'0px'}}>HOW YOUR PERSONAL DATA IS USED</strong></p><br/>
<p class="num2">We generally use your personal data in the following ways:</p>
<div class="num2">
  <ul class="li">
    <li class="p1">to provide the Service to you;</li>
    <li class="p1">to help us establish, exercise, or defend legal claims;</li>
    <li class="p1">to improve your experience of using the Service Channels and to improve the Service; and</li>
    <li class="p1">to carry out analytics.</li>
  </ul>
</div>
<br/><p class="p1"><span class="num">4.1</span> <strong style={{marginLeft:'0px'}}>To provide the Service to you:</strong></p><br/>
<p class="p1"><span style={{marginRight:'16px'}}>4.1.1</span> <strong>Why and how we process your information:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Processing your personal data to register you as a user of the Service;</li>
    <li class="p1">Processing your personal data contained in reports provided by users of the Service; and</li>
    <li class="p1">Reviewing, verifying, and keeping records of our conversations, communications, and interactions as part of the Service.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.1.2</span> <strong>Types of personal data used:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Telephone number;</li>
    <li class="p1">Any other optional personal data you provide to us (e.g. your name and e-mail address);</li>
    <li class="p1">Any personal data a user may provide to us when using the Service to report criminal activity (e.g. your name and any other information which may identify you); and</li>
    <li class="p1">Information that a user has chosen to tell us or that you have chosen to tell a G-Chime staff member as part of conversations, communications, and interactions.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.1.3</span> <strong>Legal basis relied upon:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Legitimate interests, namely it is in our interests and in your interests:
      <ol class="ol">
        <li>to facilitate your access to the Service;</li>
        <li>to ensure we have an accurate record of users of the Service; and</li>
        <li>to verify the people we deal with.</li>
      </ol>
    </li>
    <li class="p1">Legitimate interests, namely it is in our interests to provide an information service that allows users to be aware of criminal activity reported in an area.</li>
    <li class="p1">Legitimate interests, namely it is in our interests and your interests:
      <ol class="ol">
        <li>to respond to your queries or comments about the Service;</li>
        <li>to ensure we provide you with relevant information; and</li>
        <li>to ensure our business runs smoothly.</li>
      </ol>
    </li>
    <li class="p1">We may also need to process your personal data to comply with our legal obligations.</li>
  </ul>
</div>
<br/><p class="p1"><span class="num">4.2</span> <strong style={{marginLeft:'0px'}}>To help us establish, exercise or defend legal claims:</strong></p><br/>
<p class="p1"><span style={{marginRight:'16px'}}>4.2.1</span> <strong>Why and how we process your information:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">To help us to establish, exercise, or defend legal claims.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.2.2</span> <strong>Types of personal data used:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Telephone number;</li>
    <li class="p1">Any other optional personal data you provide to us (e.g. your name);</li>
    <li class="p1">Any personal data a user may provide to us when using the Service to report criminal activity (e.g. your name and any other information which may identify you); and</li>
    <li class="p1">Information that a user has chosen to tell us or that you have chosen to tell a G-Chime staff member as part of conversations, communications, and interactions.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.2.3</span> <strong>Legal basis relied upon:</strong><span class="s3">5</span></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Legitimate interests, namely it is in our interests for us to be enabled to establish and defend our legal rights and understand our obligations, and seek legal advice in connection with them.</li>
  </ul>
</div>
<p class="p1"><span class="num">4.3</span> <strong style={{marginLeft:'0px'}}>To improve your experience of using our website and to improve the Service:</strong></p>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.3.1</span> <strong>Why and how we process your information:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Processing your personal data in connection with your use of our website to improve your experience of using our website and to improve the Service.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.3.2</span> <strong>Types of personal data used:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">IP address;</li>
    <li class="p1">type, version and language of Internet browser, operating system; and</li>
    <li class="p1">version, type of device, screen resolution, referring and exit web pages.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.3.3</span> <strong>Legal basis relied upon:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Other than essential cookies, we process this information with your consent;</li>
    <li class="p1">If you would like to find out more about cookies, including how we use them and what choices are available to you, see Section 10 &ndash; Cookies.</li>
  </ul>
</div>
<br/><p class="p1"><span class="num">4.4</span> <strong style={{marginLeft:'0px'}}>To carry out analytics:</strong></p><br/>
<p class="p1"><span style={{marginRight:'16px'}}>4.4.1</span> <strong>Why and how we process your information:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Processing your anonymised data to develop insights into reported criminal activity and publish or share the insights developed through this process.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.4.2</span> <strong>Types of personal data used:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">G-Chime may aggregate and analyse your &lsquo;de-identified&rsquo; information (i.e. stripped of all uniquely identifiable attributes and that cannot subsequently be re-identified) together with that of other users and information received from other sources.</li>
  </ul>
</div>
<br/><p class="p1"><span style={{marginRight:'16px'}}>4.4.3</span> <strong>Legal basis relied upon:</strong></p><br/>
<div class="num2">
  <ul class="li">
    <li class="p1">Legitimate interests, namely it is in our interests and your interests:
      <ol class="ol">
        <li>to develop insights into criminal activity and to identify behaviour/usage patterns;</li>
        <li>to improve the Services.</li>
      </ol>
    </li>
  </ul>
</div>

<br/><p class=""><br/><span class="num">5.</span> <strong style={{marginLeft:'0px'}}>HOW DO WE SHARE YOUR PERSONAL DATA?</strong></p><br/>
<p class="num2">G-Chime will not sell your mobile phone number or any e-mail address you provide to third parties.</p>
<p class="num2">Where appropriate and in accordance with local laws and requirements, G-Chime may share your personal data, in various ways and for various reasons, with the following categories of people:</p>
<div class="num2">
  <ul class="li">
    <li class="p1">any of G-Chime’s group companies;</li>
    <li class="p1">where G-Chime is obligated to do so by applicable law or court order, law enforcement and government authorities, provided however that G-Chime reserves the right to share your personal data with law enforcement and government authorities should G-Chime reasonably suspect that you have knowingly added false or misleading crimes via the Service Channels or that you are otherwise seeking to damage the Service in any manner, including, but not limited to by way of cybercrime;</li>
    <li class="p1">third party service providers (including suppliers) who perform functions on G-Chime's behalf (including SMS-service providers and G-Chime's professional advisers such as lawyers, auditors, accountants and administrators);<span class="s3">6</span></li>
    <li class="p1">where you have expressly given your consent to be contacted in relation to a crime you have reported, those organisations that have a legitimate interest in the data G-Chime is able to provide; and</li>
    <li class="p1">if G-Chime merges with or is acquired by another business or company in the future, it may share your personal data with the new or prospective owners of the business or company as part of the merger or acquisition process (and may provide you with notice of this disclosure).</li>
  </ul>
</div>

<br/><p class="p1"><br/><span class="num">6.</span> <strong style={{marginLeft:'0px'}}>HOW DO WE SAFEGUARD YOUR PERSONAL DATA?</strong></p><br/>
<div class="num2">
<p class="p1">G-Chime is committed to taking all reasonable and appropriate steps to protect the personal
data that it holds from misuse, loss, or unauthorised access. G-Chime does this by having in
place a range of appropriate technical and organisational measures including encrypted
storage and transmission of mobile phone numbers and compartmentalised data access.
These include measures to deal with any suspected data breach.
If you suspect any misuse, loss of, or unauthorised access to your personal data, please let
us know immediately. Details of how to contact us can be found in Section 8 – Who is
Responsible for Processing Your Personal Data and How to Contact Us.</p>
</div>


<br/><p class="p1"><br/><span class="num">7.</span> <strong style={{marginLeft:'0px'}}>HOW LONG DO WE KEEP YOUR PERSONAL DATA?</strong></p><br/>
<div class="num2">
 <p class="p1">G-Chime will ordinarily process your data throughout the course of our interactions and will
then retain it for an appropriate amount of time afterwards. The precise length of time will
depend on the type of data, G-Chime’s legitimate business needs, and other legal
requirements that may require G-Chime to retain it for certain minimum periods.
There may be some other legal or risk management requirements to retain data, including
where certain data might be relevant to any potential litigation (bearing in mind relevant
limitation periods).
In determining the appropriate retention period for different types of personal data, G-Chime
always considers the amount, nature, and sensitivity of the personal data in question, the
potential risk of harm from unauthorised use or disclosure of that personal data, the purposes
for which G-Chime needs to process it and whether those purposes can be achieved by
other means (in addition of course to ensuring compliance with legal and risk management
obligations, as described above).
Once G-Chime has determined that it no longer needs to hold your personal data, G-Chime
will delete it from its systems.</p> 
</div>
<br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 8</div>
 

<div style={{paddingLeft:'32px'}}>
<span><strong>WHO IS RESPONSIBLE FOR PROCESSING YOUR PERSONAL DATA AND HOW TO CONTACT US</strong></span>
</div>
</div>
<br/>
<div class="num2">
<p class="p1">The responsible party and company responsible for compliance with this Privacy Policy is G-
Chime Solutions Limited of 1 Cathedral Piazza, London, SW1E 5BP, United Kingdom.
If you would like further information about how G-Chime processes your personal
information, if you have any concerns regarding this Privacy Policy, or if you wish to exercise
your legal rights, please write to <a href="mailto:dataprivacy@crimechime.co.za">dataprivacy@crimechime.co.za</a>.</p>
</div>


<br/><p class="p1"><br/><span class="num">9.</span> <strong style={{marginLeft:'0px'}}>HOW DO WE STORE AND TRANSFER YOUR DATA INTERNATIONALLY?</strong></p><br/>
<div class="num2">
  <p class="p1">G-Chime is an entity based in the United Kingdom and in order to carry out the purposes
  described in this Privacy Policy, your data may be transferred internationally:</p>
  <ul class="li">
    <li class="p1">between and within entities within the G-Chime group, including the United Kingdom;</li>
    <li class="p1">to third parties, including those who provide storage or transmission services and</li>
    <li class="p1">those referred to in Section 5 - How do we share your Personal Data?; or</li>
    <li class="p1">to a cloud-based storage provider.</li>
  </ul>
  <p class="p1">To ensure that your personal information receives an adequate level of protection, G-Chime
  has put in place appropriate procedures with the third parties it may share your personal data<span class="s3">7</span>
  with to ensure that your personal information is treated by those third parties in a way that is
  consistent with and which respects the law on data protection.</p>
</div>

<br/><p class="p1"><br/><span class="num">10.</span> <strong style={{marginLeft:'0px'}}>COOKIES</strong></p><br/>
<p class="num">10.1 <strong style={{marginLeft: '20px'}}>Cookie Definition</strong></p><br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 10.1.1</div>
 

<div style={{paddingLeft:'16px'}}>
<span>When you first visit the G-Chime website, you will be presented with a pop-up screen informing you of G-Chime&apos;s use of cookies and asking you to provide your
  consent for such use.</span>
</div>
</div>
<br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 10.1.2</div>
 

<div style={{paddingLeft:'16px'}}>
<span>The pop-up screen may not reappear for all your subsequent visits to the website,but if you wish, you can withdraw your consent at any time by changing the cookie
  settings in your browser to block or reject certain cookies, delete existing ones, or
  change them.</span>
</div>
</div>

 
  <br/>
  <div style={{display: 'flex', flexDirection:'row'}}>
  <div> 10.1.3</div>
 

<div style={{paddingLeft:'16px'}}>
<span> A &quot;cookie&quot; is a piece of information that is stored on your computer&apos;s hard drive which records your navigation of a website so that, when you revisit that website,
  it can present tailored options based on the information stored about your last visit.
  Cookies can also be used to analyse traffic and for advertising and marketing
  purposes.</span>
</div>
</div>
  
  <br/>
 
  <p class="p1">10.2 <strong style={{marginLeft: '20px'}}>How Cookies are used</strong></p><br/>
  <div style={{display: 'flex', flexDirection:'row'}}>
  <div> 10.2.1</div>
 

<div style={{paddingLeft:'16px'}}>
<span> G-Chime use cookies to track your use of our website. This enables G-Chime to understand how you use the site and track any patterns that emerge individually or
  from larger groups. This helps G-Chime to develop and improve our website and
  services in response to what users want and need.</span>
</div>
</div>
<br/>
  <p class="p1"><span style={{marginRight:'8px'}}>10.2.2 </span>Cookies are either:</p><br/>
  <div class="num2">
  <div class="li">
    <ol type="A">
    <li>Session cookies: these are only stored on your computer during your web
  session and are automatically deleted when you close your browser - they
  usually store an anonymous session ID allowing you to browse a website
  without having to log in to each page but they do not collect any information
  from your computer.</li><br/>
    <li>Persistent cookies: is stored as a file on your computer and it remains there
  when you close your web browser. The cookie is read by the website that
  created it when you visit that website again. G-Chime uses persistent cookies
  for Google Analytics and similar services and for personalisation (see below).</li>
  </ol>
  </div>
  </div>
  <br/>
  <p class="p1"><span style={{marginRight:'8px'}}>10.2.3</span> Cookies are also categorised as follows:</p><br/>
  <div class="num2">
  <div class="li">
    <ol type="A">
    <li> Strictly necessary cookies: Essential to enable you to use the site effectively
  and therefore cannot be turned off. Without these cookies, the services
  available to you on G-Chime's website cannot be provided. These cookies do
  not gather information that could be used for marketing or remembering where
  you have been on the internet.</li><br/>
    <li>Performance cookies: These cookies enable G-Chime to monitor and improve
  the performance of our site. For example, they allow G-Chime to count visits,
  identify traffic sources, and see which parts of the site are most popular.</li><br/>
  <li>Functionality cookies: These cookies allow the website to remember choices
  you make (such as your language or the region you are in) and provide
  enhanced features. They may also be used to provide services you have
  requested. The information these cookies collect is usually anonymised.</li><br/>
    <li>Personalisation cookies: These cookies help G-Chime to provide users with a
  tailored experience. These cookies are persistent (for as long as you are
  registered with us) and mean that when you log in or return to the website, you
  may see contextual advertisements that are similar to content that you have
  previously browsed.</li>
  </ol>
  </div>
</div>

<br/><div style={{display: 'flex', flexDirection:'row'}}>
  <p class="p1"><br/><span class="num">11.</span> <strong style={{marginLeft:'0px'}}>HOW CAN YOU ACCESS, AMEND OR TAKE BACK THE PERSONAL DATA THAT YOU HAVE GIVEN TO US?</strong></p>
 


</div>

<br/>
<div class="num2">
  <p class="p1">You retain various rights in respect of your personal data. These are described in more detail
below.
To communicate about these rights, please contact us (with the details given at Section 8 &ndash;
Who is Responsible for Processing Your Personal Data and How to Contact Us). We will
seek to deal with your request within one month (subject to any extensions to which we are
lawfully entitled).
NOTE: G-Chime may keep a record of your communications to help it resolve any issues
that you raise.</p>
</div>

<br/><p class="p1"><span class="num">11.1</span> <strong>Right to object</strong></p><br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.1.1</div>
 

<div style={{paddingLeft:'23px'}}>
<span>This enables you to object to G-Chime processing your personal data. This is possible for one of the following four reasons:</span>
</div>
</div>
{/* <p class="p1"><span style={{marginRight: '18px'}}>11.1.1</span></p> */}
<div class="num2">
  <p class="p1"></p>
  <p class="p1">(i) our legitimate interests;</p>
  <p class="p1">(ii) to enable us to perform a task in the public interest or exercise official authority;</p>
  <p class="p1">(iii) for scientific, historical, research, or statistical purposes.</p>
</div>
<br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.1.2</div>
 

<div style={{paddingLeft:'16px'}}>
<span>The &quot;legitimate interests&quot; category above is most likely to apply. If your objection relates to us processing your personal data because G-Chime deems it necessary for your legitimate interests, G-Chime must act on your objection by ceasing the activity in question unless:</span>
</div>
</div>
<br/>
<div class="num2">
  <div class="li">
    <ol type="A">
    <li>G-Chime can show that it has compelling legitimate grounds for processing which overrides your interests; or</li><br/>
    <li>G-Chime is processing your data for the establishment, exercise, or defence of a legal claim.</li>
  </ol>
  </div>
  
</div>


<br/>
<p class="p1"><span>11.2</span> <strong style={{marginLeft: '20px'}}>Right to withdraw consent:</strong></p><br/>

<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.2.1</div>
 

<div style={{paddingLeft:'16px'}}>
<span> Where we have obtained your consent to process your personal data, you may
withdraw this consent at any time and we will cease to carry out the particular
activity that you previously consented to unless we consider that there is an
alternative reason to justify our continued processing of your data for this purpose,
in which case, we will inform you of this condition.</span>
</div>
</div>

<br/><p class="p1"><span >11.3</span> <strong style={{marginLeft: '20px'}}>Data subject access requests</strong></p><br/>

<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.3.1</div>
 

<div style={{paddingLeft:'16px'}}>
<span>You may ask G-Chime to confirm what information it holds about you at any time,
and request it to modify, update, or delete such information. G-Chime may ask you
to verify your identity and for more information about your request. If G-Chime
provides you with access to the information that it holds about you, it will not charge
you for this unless your request is &quot;manifestly unfounded or excessive&quot;. If you
request further copies of this information from G-Chime, it may charge you a
reasonable administrative cost where legally permissible. Where G-Chime is
legally permitted to do so, it may refuse your request and provide you with the
reasons for doing so. </span>
</div>
</div>

<br/><p class="p1"><span>11.4</span> <strong style={{marginLeft: '20px'}}>Right to erasure</strong></p><br/>

<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.4.1</div>
 

<div style={{paddingLeft:'16px'}}>
<span> You have the right to request that G-Chime erases your personal data in certain circumstances. Normally, the information must meet one of the following criteria:</span>
</div>
</div>


<div class="num2"><br/>
<div class="li">
  <ol type="A">
  <li>the data is no longer necessary for the purpose for which G-Chime originally collected and/or processed them;</li><br/>
  <li>where previously given, you have withdrawn your consent to G-Chime
processing your data, and there is no other valid reason for it to continue
processing;</li><br/>
  <li> the data has been processed unlawfully (i.e., in a manner which does not
comply with UK GDPR or the POPI Act);</li><br/>
  <li> it is necessary for the data to be erased in order for G-Chime to comply with its
legal obligations as the responsible party; or</li><br/>
  <li> if G-Chime processes the data because it believes it necessary to do so for its
legitimate interests, you object to the processing and G-Chime is unable to
demonstrate overriding legitimate grounds for the continued processing.</li><br/>
 
  </ol>
</div>
</div>

<br/>
<p class="p1"><span style={{marginRight: '14px'}}>11.4.2</span>G-Chime would only be entitled to refuse to comply with your request for erasure </p><br/>
<div class="li">
  <div class="num2">
  <ol type="A">
  <li>for one of the following reasons:</li><br/>
  <li>to exercise the right of freedom of expression and information;</li><br/>
  <li> to comply with legal obligations or for the performance of a public interest task or exercise of official authority;</li><br/>
  <li> for public health reasons in the public interest;</li><br/>
  <li> for archival, research, or statistical purposes; or</li><br/>
  <li>to exercise or defend a legal claim.</li><br/>
  </ol>
</div>
</div>


<br/><p class="p1"><span>11.5</span> <strong style={{marginLeft: '20px'}}>Right to restrict processing</strong></p><br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.5.1</div>
 

<div style={{paddingLeft:'18px'}}>
<span>You have the right to request that G-Chime restricts its processing of your personal data in certain circumstances. G-Chime can only continue to store your data, and
will not be able to carry out any further processing activities with it until either: </span>
</div>
</div>
<div class="num2"></div>
<div class="num2">
  
    <p class="p1">(i) one of the circumstances listed below is resolved; </p>
    <p class="p1">(ii) you consent; or</p>
    <p class="p1">(iii) further processing is necessary for either the establishment, exercise, or defence of legal
claims, or the protection of the rights of another individual.</p>
 
</div>

<br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.5.2</div>
 

<div style={{paddingLeft:'18px'}}>
<span>The circumstances in which you are entitled to request that G-Chime restrict the processing of your personal data include the following:</span>
</div>
</div>
<div class="num2">
<br/>
<div class="li">
  <ol type="A">
  <li>
    where you dispute the accuracy of the personal data that G-Chime is
    processing about you. In this case, the processing of your personal data will
    be restricted for the period during which the accuracy of the data is verified;
    
  </li>
  <br/>
  <li>
    where you object to G-Chime’s processing of your personal data for its
    legitimate interests. Here, you can request that the data be restricted while G-
    Chime verifies the grounds for processing your personal data;
   
  </li> <br/>
  <li>
    where G-Chime's processing of your data is unlawful, but you would prefer it
    to restrict the processing of it rather than erasing it; and
   
  </li> <br/>
  <li>
    where G-Chime has no further need to process your personal data but you
    require the data to establish, exercise, or defend legal claims.
    
  </li><br/>
</ol>
</div>

</div>
<br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.6</div>
 

<div style={{paddingLeft:'32px'}}>
<span> If G-Chime has shared your personal data with third parties, it will notify them about the restricted processing unless this is impossible or involves disproportionate effort. G-Chime
will notify you before lifting any restriction on processing
your personal data.</span>
</div>
</div>


<br/><p class="p1"><span>11.7</span> <strong style={{marginLeft: '20px'}}>Right to rectification</strong></p><br/>

<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.7.1</div>
 

<div style={{paddingLeft:'18px'}}>
<span> You also have the right to request that G-Chime rectify any inaccurate or
incomplete personal data that it holds about you. If G-Chime has shared this
personal data with third parties, it will notify them about the rectification unless this
is impossible or involves disproportionate effort. Where appropriate, G-Chime will
also tell you which third parties it has disclosed the inaccurate or incomplete
personal data to. Where G-Chime thinks that it is reasonable for it not to comply
with your request, it will explain the reasons for this decision.</span>
</div>
</div>


<br/><p class="p1"><span>11.8</span> <strong style={{marginLeft: '20px'}}>Right of data portability</strong></p><br/>


<div class="num2"><p class="p1">If you wish, you have the right to transfer your personal data between responsible parties. This right of data portability applies to:</p>
<p class="p1"> (i) personal data that G-Chime processes
automatically (i.e., without any human intervention) and<br/> (ii) personal data provided by you.</p></div>

<br/><p class="p1"><span>11.9</span> <strong style={{marginLeft: '20px'}}>Right to lodge a complaint with a supervisory authority</strong></p><br/>
<div class="num2"><p class="p1">You also have the right to lodge a complaint with your local supervisory authority.The supervisory authority in South Africa is the Information Regulator at: 27 Stiemens Street,
Braamfontein, Johannesburg, 0001 (t: 010 023 5200 e: https://inforegulator.org.za/).
The supervisory authority in the United Kingdom is the Information Commissioner&apos;s Office
at: Information Commissioner&apos;s Office, Wycliffe House, Water Lane, Wilmslow, Cheshire,
SK9 5AF (T: 0303 123 1113 E: icocasework@ico.org.uk).</p>
<p class="p1"></p></div>
<br/>
<div style={{display: 'flex', flexDirection:'row'}}>
  <div> 11.10</div>
 

<div style={{paddingLeft:'24px'}}>
<span> If you would like to exercise any of these rights, or withdraw your consent to the processing of your personal data (where consent is our legal basis for processing your personal data),
see Section 8 &ndash; Who is Responsible for Processing Your Personal Data and How to Contact
Us for details on how to contact us.
NOTE: G-Chime may keep a record of your communications to help it resolve any issues that you
raise.
It is important that the personal information G-Chime holds about you is accurate and current. Please
keep G-Chime informed if your personal information changes during the period for which it holds
your data.
Should you have any questions, comments or concerns about our Privacy Policy, see Section 8 &ndash;
Who is Responsible for Processing Your Personal Data and How to Contact Us for details on how to
contact us.</span>
</div>
</div>




</>
            </Typography>
            </Box>
    </div>
  );
};

export default PrivacyPolicy;

