import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './pp_styles.css';
import image from '../../../assets/images/CrimeChimeContact.png';

const ContactUs = () => {
  return (
    <div style={{ backgroundColor: '' }}>
      <Container sx={{ py: 6 }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
            <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
              Contact Us
            </Typography>
            <Box
              component="img"
              src={image}
              alt="Contact Us"
              sx={{
                width: '100%', // Adjust the width as needed
                maxWidth: '400px', // Set a max-width to control the image size
                height: 'auto', // Maintain aspect ratio
                display: 'block',
                margin: '0 auto', // Center the image
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;