import React, { useState, useEffect } from 'react';
import { Container, Typography, useTheme, Grid, Box, Checkbox } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import bribe from '../../../assets/images/bribe.png';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from 'src/store/reducers/registerReport';
import { isArray } from 'lodash';

const Page13 = () => {
  const { bribery: checked, is_bribery_paid } = useSelector(state => state.reportRegister.data);
  const dispatch = useDispatch();
  const theme = useTheme();
  let [bribeOptions, setBribeOptions] = useState(checked);

  useEffect(() => {
    setBribeOptions(checked);
  }, [checked]);

  const handleBribeOptionChange = (event) => {
    const value = Number(event.target.value);
    let updatedBribeOptions;

    if (value === 0) {
      updatedBribeOptions = [0];
    } else {
      if(!isArray(bribeOptions)){
        bribeOptions=bribeOptions?.split(',').map(Number);
      }
      updatedBribeOptions = bribeOptions?.includes(value)
        ? bribeOptions.filter((option) => option !== value)
        : [...bribeOptions.filter((option) => option !== 0), value];
    }

    setBribeOptions(updatedBribeOptions);
    dispatch(setPage({ bribery: updatedBribeOptions }));
    if (updatedBribeOptions.includes(0)) {
      dispatch(setPage({ is_bribery_paid: 0 }));
    }
  };

  const handleBribePaidChange = (event) => {
    const value = Number(event.target.value);
    dispatch(setPage({ is_bribery_paid: value }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <Grid container spacing={2} justifyContent="center" sx={{ paddingY: 0 }} mt={5}>
          <Grid item xs={10} className="pt-5">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginRight: '5px', width: '20px' }} />
              <Typography variant="h1" align="center" style={{ fontWeight: 'bold', paddingBottom: '5px', fontSize: '24px' }}>
                Bribery
              </Typography>
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginLeft: '5px', width: '20px' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px' }}>
              <img src={bribe} alt="Vehicle Theft" style={{ height: '100px' }} />
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '5%' }}>
              <Typography id="number-picker-label" style={{ paddingBottom: '16px', textAlign: 'center', fontSize: '16px' }}>
                Was bribery involved?
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox checked={bribeOptions?.includes(0)} value={0} onChange={handleBribeOptionChange} />
                <Typography variant="h6" sx={{ fontWeight: 'normal', px: 2, textAlign: 'center' }}>
                  Does not apply
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox checked={bribeOptions?.includes(1)} value={1} onChange={handleBribeOptionChange} />
                <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                  Bribe requested by police officer
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox checked={bribeOptions?.includes(2)} value={2} onChange={handleBribeOptionChange} />
                <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                  Bribe requested by civil servant
                  <br />
                  <span style={{ textAlign: 'left', fontSize: '0.8rem' }}>(not a police officer)</span>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox checked={bribeOptions?.includes(3)} value={3} onChange={handleBribeOptionChange} />
                <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                  Bribe requested by politician
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox checked={bribeOptions?.includes(4)} value={4} onChange={handleBribeOptionChange} />
                <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                  Bribe offered by the member of general public
                </Typography>
              </Box>
              {bribeOptions?.length > 0 && !bribeOptions?.includes(0) && (
                <>
                  <Box style={{ marginBottom: '0px', flexDirection: 'row', textAlign: 'center', alignItems: 'center', my: 2, pl: 2 }}>
                    <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'center' }}>
                      Was a bribe paid?
                    </Typography>
                  </Box>
                  <Box style={{ display: 'flex', marginTop: '0px', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                    <Checkbox checked={is_bribery_paid === 0} value={0} onChange={handleBribePaidChange} />
                    <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                      Unknown
                    </Typography>
                    <Checkbox checked={is_bribery_paid === 1} value={1} onChange={handleBribePaidChange} />
                    <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                      Yes
                    </Typography>
                    <Checkbox checked={is_bribery_paid === 2} value={2} onChange={handleBribePaidChange} />
                    <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                      No
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default Page13;
