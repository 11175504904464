import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getMyReport, deleteReport } from 'src/store/api/report';
import { Box, Button, Container, Grid, Tooltip, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import UserSideName from './components/UserSideNav';
import Page from '../../../components/Page';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { setCrimeIndex, setEdit, setLock, setNearbyReports, setPage } from 'src/store/reducers/registerReport';
import { jwtDecode } from "jwt-decode";
import { css } from '@emotion/css';
import ClampLines from 'react-clamp-lines';
import { useTheme } from '@emotion/react';
import { StyledGrid } from './StyledGrid';
import { useMediaQuery } from '@mui/material';

const MyReport = () => {
    const isScreenSmall = useMediaQuery('(max-width:1194px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { reports, error } = useSelector((state) => ({ ...state.report }));
    const [openDialog, setOpenDialog] = useState({ status: false, id: null });
    const theme = useTheme();

    useEffect(() => {
        dispatch(getMyReport({}));
    }, [dispatch]);

    const handleOpenDialog = (id) => {
        setOpenDialog({ status: true, id });
    };

    const handleCloseDialog = () => {
        setOpenDialog({ status: false, id: null });
    };

    const handleDelete = () => {
        if (openDialog.id) {
            dispatch(deleteReport({ id: openDialog.id }));
            handleCloseDialog();
        }
    };

    const handleEdit = (report) => {
        let newReport = { ...report, crime: null, specific_crime: null };
        const report_image = newReport.report_image;
        newReport.files = newReport.fileName = (process.env.REACT_APP_API_URL + "/" + report_image?.path) || null;
        newReport.report_image = null;
        const keyList = Object.keys(newReport);
        keyList.forEach((key) => {
            if (newReport[key] == null) {
                delete newReport[key];
            }
        });
        dispatch(setEdit(true));
        dispatch(setPage(newReport));
        navigate("/report/add");
    };

    function isImage(url) {
        const imageExtensions = ['.apng', '.bmp', '.gif', '.ico', '.cur', '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg', '.tif', '.tiff', '.webp'];
        const lowerCaseUrl = url.toLowerCase();
        return imageExtensions.some(extension => lowerCaseUrl.endsWith(extension));
    }

    function isVideo(url) {
        const videoExtensions = ['.mp4', '.webm', '.ogg', '.ogv', '.avi', '.mov', '.wmv', '.flv', '.mkv', '.m4v', '.3gp'];
        const lowerCaseUrl = url.toLowerCase();
        return videoExtensions.some(extension => lowerCaseUrl.endsWith(extension));
    }

    return (
        <Page title="My Reports">
            <Container sx={{ marginTop: '20px' }}>
                <Grid container spacing={3}>
                    <UserSideName />
                    {reports?.data ? (
                        <Grid item md={9} xs={12}>
                            <Grid container spacing={3}>
                                {reports.data && reports.data.map((report, index) => {
                                    let path = (report?.report_image && report.report_image?.path);
                                    if (!(path === null || path === '')) {
                                        path = path;
                                    }
                                    return (
                                        <Grid item lg={4} md={6} xs={12} key={index}>
                                            <Box sx={{ width: "100%", display: 'flex', justifyContent: "center" }}>
                                                <Card sx={{ width: { xs: "100%" }, maxWidth: { sm: "300px", md: "100%" }, maxHeight: "550px", height: "550px", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                    {(!(path === null || path === '') && isImage(path)) ? (
                                                        <CardMedia
                                                            component="img"
                                                            alt="media"
                                                            image={path}
                                                            width="300px"
                                                            height="300px"
                                                            sx={{ objectFit: "contain", maxWidth: "300px", maxHeight: "200px" }}
                                                        />
                                                    ) : (!(path === null || path === '') && isVideo(path)) ? (
                                                        <video className="VideoInput_video" width="300px" height="200px" sx={{ maxWidth: "300px", maxHeight: "200px" }} controls src={path} />
                                                    ) : (
                                                        <Box sx={{ display: "flex", width: "300px", height: "200px", alignItems: "center", justifyContent: "center" }}>
                                                            No media Available
                                                        </Box>
                                                    )}
                                                    <Box sx={{ width: '100%', paddingRight: '8px' }}>
                                                        <Grid container justifyContent="flex-end">
                                                            <Grid item>
                                                                <Button
                                                                    sx={{
                                                                        paddingRight: '5px',
                                                                        marginTop: '5px',
                                                                        backgroundColor: 'white',
                                                                        color: 'red',
                                                                        border: '1px solid red',
                                                                        '&:hover': {
                                                                            backgroundColor: 'white',
                                                                            borderColor: 'red',
                                                                        }
                                                                    }}
                                                                    onClick={() => handleOpenDialog(report.id)}
                                                                    size="small"
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <CardContent>
                                                        <Tooltip title={report.location}>
                                                            <Box className={css`
                                                                max-height:100px;
                                                                min-height:100px;
                                                                height:100px;
                                                                font-size: 18px;
                                                                font-weight: bold;
                                                                margin-top: 0;
                                                                margin-bottom: 0.5em;
                                                            `}>
                                                                <ClampLines
                                                                    text={report.location}
                                                                    lines={3}
                                                                    ellipsis="..."
                                                                    innerElement="p"
                                                                    stopPropagation={true}
                                                                    buttons={false}
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                        <Tooltip title={jwtDecode(report.description).description || ""}>
                                                            <Box className={css`
                                                                margin-top:15px;
                                                                max-height:22px;
                                                                min-height:22px;
                                                                height:22px;
                                                                font-size:14px;
                                                                font-weight:light;
                                                                color:${theme.palette.text.secondary};
                                                            `}>
                                                                <ClampLines
                                                                    text={jwtDecode(report.perpetrators_des).perpetrators_des || "No Description"}
                                                                    lines={2}
                                                                    ellipsis="..."
                                                                    innerElement="p"
                                                                    stopPropagation={true}
                                                                    buttons={false}
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                    </CardContent>
                                                    <CardActions sx={{ paddingLeft: '24px' }}>
                                                        <Button onClick={() => {
                                                            navigate(`/crimedetails?id=${report.id}&show=false&type=all`)
                                                        }} size="small">Show on map</Button>
                                                        <Button onClick={() => {
                                                            dispatch(setCrimeIndex({ index, viewCrime: true }))
                                                            navigate("/")
                                                        }} size="small">View Report</Button>
                                                         <Button
      onClick={() => handleEdit(report)}
      size="small"
      sx={{ paddingY: isScreenSmall ? '4px' : '15px' }}
    >
      Edit
    </Button>
                                                    </CardActions>
                                                </Card>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    ) : (
                        <StyledGrid item md={9} xs={12}>
                            <CircularProgress />
                        </StyledGrid>
                    )}
                </Grid>
            </Container>
            <Dialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog.status}>
                <DialogContent sx={{
                    padding: '60px !important',
                    textAlign: 'center'
                }}>
                    <Typography variant="h3">
                        Are you sure ?
                    </Typography>
                    <Typography variant="h6" sx={{
                        padding: '20px 40px 30px 40px !important',
                        fontWeight: '500'
                    }}>
                        Do you really want to delete this record? This process cannot be reversed.
                    </Typography>
                    <Button variant="contained" onClick={handleCloseDialog} sx={{ marginRight: '10px' }}>
                        No, Cancel!
                    </Button>
                    <Button variant="contained" onClick={handleDelete}>
                        Yes, Delete it!
                    </Button>
                </DialogContent>
            </Dialog>
        </Page>
    );
};

export default MyReport;
