import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './tnc_style.css'
const Tnc = () => {
  return (
    <div style={{  }}>
      <Container sx={{ py: 6, textAlign: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
            <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>Terms and Conditions</Typography>
            
          </div>
        </div>
      </Container>
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Center content horizontally
        paddingX: { xs: '2%', sm: '15%' }, // Disable paddingX on mobile screens
        marginLeft: { xs: 0, sm: '5%' }, // Disable marginLeft on mobile screens
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: '18px',
          maxWidth: '4000px',
          color: '#333',
          mb: 4,
          lineHeight: 1.5,
          textAlign: 'justify',
          width: '100%', // Ensure typography takes full width within the Box
        }}
      >
      <>
      
<p class="p1"><strong>ACCEPTANCE OF TERMS AND CONDITIONS</strong></p><br/>
<p class="p1">When accessing the website at www.crimechime.co.za (<strong>Website</strong>) or the CrimeChime mobile phone or tablet
applications (<strong>Mobile Applications</strong>) (collectively, &ldquo;<strong>the Service Channels</strong>&rdquo;) or using any of the features and
functionalities available through these Service Channels, you enter into an agreement with CrimeChime (which
is owned and operated by G-Chime Solutions Limited, an English company, #14934937, wholly owned by
Gemfields Limited (<strong>G-Chime</strong>)), on the terms and conditions set out in these Terms and Conditions (collectively
the &ldquo;<strong>Terms</strong>&rdquo;). If you do not agree to the Terms, you must not use the Service Channels or using any of the
features and functionalities available through the Service Channels.</p>
<br/><p class="p1"><strong>SERVICES</strong></p><br/>
<p class="p1">The service that G-Chime provides is an information service that allows users who register using a South
African mobile phone to report criminal activity within South Africa. The data is received by G-Chime from
users of the Service Channels and consolidated so that any user is able to visualise and analyse criminal
activity that has been reported by users of the Service Channels. This data should assist users and wider
stakeholders with improved awareness of reported crimes and aid future crime prevention (<strong>Service</strong>).</p>

<br/><p class="p1"><strong>AMENDMENT OF TERMS</strong></p><br/>
<p class="p1">On each occasion that you access any of the Service Channels, you agree to be bound by the Terms. G-Chime may change the Terms at any time and will publish the amended Terms (<strong>the New Terms</strong>) on the Service Channels. You will be alerted to a change in the Terms, and you will be given an opportunity to review the New Terms. If you don’t agree to the New Terms, you can terminate your use of the Service at any time by ceasing to use it.</p>

<br/><p class="p1"><strong>PERSONAL INFORMATION WILL REMAIN CONFIDENTIAL</strong></p><br/>
<p class="p1">G-Chime confirms that it will protect any personal information it receives when you use the Service Channels and the Service and G-Chime will only use it in the manner set out in its <a href="https://www.crimechime.co.za/privacypolicy"><strong>Privacy Policy</strong></a>.</p>

<br/><p class="p1"><strong>REGISTRATION FOR THE SERVICE</strong></p><br/>
<p class="p1">In order to use the Service to review criminal activity reported by other users, no registration is required. In order to use the Service (i) to report information relating to criminal activity or (ii) to receive alerts about nearby crimes, you are required to register for the Service by using your South African mobile phone number. Once provided, a "one time pin" will be sent to your mobile phone number to confirm your registration and on each successive use of these aspects of the Service. If you are provided with such a "one time pin" or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>

<br/><p class="p1"><strong>FEES</strong></p><br/>
<p class="p1">There is no fee payable by users for the Service. However, G-Chime reserves the right to introduce fees provided it gives users 21 (twenty-one) days’ prior notice of the introduction of fees. If you do not agree to the introduction of fees, you can terminate your use of the Service at any time by ceasing to use it.</p>

<br/><p class="p1"><strong>INFORMATION PROVIDED</strong></p><br/>
<p class="p1">All the information you submit must be true, accurate and complete to the best of your knowledge. Please update the information using the Service if it changes. By using the Service, you grant G-Chime the right to use any information, data, materials or other content (collectively, “<strong>Content</strong>”) you provide through or to the Service, for the purpose of providing the Service and in accordance with the Privacy Policy. By providing photos or videos, you agree and warrant that the owner of such photos or videos has expressly agreed that, without any particular time limit, G-Chime may use the Content for the purposes of providing the Service and in accordance with the Privacy Policy. G-Chime’s rights in relation to Content, photos and videos shall survive any termination of your use of the Service.</p>

<br/><p class="p1"><strong>THE SERVICE MAY NOT ALWAYS BE AVAILABLE</strong></p><br/>
<p class="p1">G-Chime does not guarantee that the Service will always be available.</p>
<br/><p class="p1"><strong>PROTECTION OF SERVICE CHANNELS</strong></p><br/>
<p class="p1">You may not –</p>
<ul class="ul1">
    <li class="li2"><p class="p1">use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology for any unlawful purpose, including the unlawful access to, acquisition of, control over, copying or monitoring of the Service Channels;</p></li>
    <li class="li2"><p class="p1">use or attempt to use any engine, software, tool, agent, or other device or mechanism (including browsers, spiders, robots, avatars or intelligent agents) to unlawfully and without our knowledge navigate or search the Service Channels, other than the search engines and search agents available through the Service and generally available third-party web browsers; or</p></li>
    <li class="li2"><p class="p1">post or transmit any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features, or that otherwise interferes with the Service Channels or the Service.</p></li>
</ul >
<p class="p1">You agree not to modify, adapt, decipher, decompile, disassemble or reverse engineer any of the software comprised in or in any way making up a part of the Service Channels or services provided by the Service Channels. You understand and accept that G-Chime may monitor and log user activity, and any material contributed by users, for security purposes in order to identify any actual or potential misuse of G-Chime's Service Channels. We have the right to remove any material contributed by users from the Service Channels.</p>

<br/><p class="p1"><strong>UNLAWFUL ACTIVITY AND USER FORUMS</strong></p><br/>
<p class="p1">You may not use the Service to post any message or material that is unlawful, defamatory, abusive, indecent, threatening, harmful, obscene, racially offensive, or pornographic. G-Chime will remove any posting or message that infringes this prohibition and, if you continue posting messages or material of this kind, G-Chime may terminate your use of the Service and report you to the relevant authorities.</p>

<br/><p class="p1"><strong>TERMINATION OF USE OF THE SERVICE</strong></p><br/>
<p class="p1">You may terminate your use of the Service at any time by ceasing to use the Service.</p>
<ul class="ul1">
    <li>G-Chime may terminate your subscription to the Service &ndash; at any time by giving you notice; or</li>
    <li> without giving you notice in the event of you breaching these Terms.</li>
</ul >
<p class="p1">On termination &ndash;</p>
<ul  class="ul1" >
    <li>all your rights under these Terms will terminate immediately; and</li>
    <li> G-Chime will, at your request, close your account and remove from its records any personal data subject to the terms of the Privacy Policy.</li>
</ul >

<br/><p class="p1"><strong>BREACH</strong></p><br/>
<p class="p1">Should either you or G-Chime (&quot;<strong>the Defaulting Party</strong>&quot;) breach these Terms and fail to remedy the breach within fourteen days of receiving written notice from the other party (&quot;<strong>the Aggrieved Party</strong>&quot;), the Aggrieved Party may, without prejudice to its other rights in law, terminate the Service agreement or claim immediate specific performance of all of the Defaulting Party&apos;s obligations whether or not due for performance.</p>
<p class="p1">If G-Chime has reason to believe that you have, or you are likely to, use or allow another person to use its Service Channels in breach of its Terms of Service, G-Chime may take such action as it reasonably deems appropriate including:</p>
<ul  class="ul1" >
    <li>immediate, temporary or permanent withdrawal of your right to use the Service Channels;</li>
    <li>immediate, temporary or permanent removal of any material contributed by you to the Service Channels;</li>
    <li>legal proceedings against you; and/or</li>
    <li>disclosure of such information to law enforcement authorities or regulators, where applicable, as G-Chime reasonably consider is appropriate.</li>
</ul >

<br/><p class="p1"><strong>INTELLECTUAL PROPERTY</strong></p><br/>
<p class="p1">Save as may be disclosed from time to time on the Service, G-Chime owns or is licensed to use all intellectual
property rights in all materials, text, drawings and data (collectively &ldquo;<strong>the Materials</strong>&rdquo;) made available on the
Service Channels. This does not include your personal information which will always belong to you and will
only be used by G-Chime in accordance with the Privacy Policy.
Any unauthorised reproduction, distribution, derivative creation, sale, broadcast or other circulation or
exploitation of the whole or any part of the Materials is an infringement of G-Chime&apos;s rights.
G-Chime owns or is licensed to use the CrimeChime Trademarks, names, logos and service marks (collectively
&ldquo;<strong>Trademarks</strong>&rdquo;) displayed on the Service Channels whether registered or unregistered. You must get G-
Chime&apos;s permission in writing if you want to use any Trademark.
You may use the Materials only to the extent necessary to enable you to view or contribute to the Service.</p>
<br/>
<p class="p1"><strong>EXTERNAL WEBSITES AND LINKING TO THE SERVICE CHANNELS.</strong></p><br/>
<p class="p1">Your use or reliance on external links is at your own risk.
G-Chime grants you a limited, nonexclusive right, revocable by us at any time, to create a hyperlink to its
Service Channels, provided such link does not portray G-Chime or the Service in a false, misleading,
derogatory or defamatory manner and/or does not misrepresent the relationship between a third party website
and G-Chime.</p>
<br/><p class="p1"><strong>WARRANTIES</strong></p><br/>
<p class="p1">
    Whilst G-Chime will endeavour to ensure the Service Channels are available, the Service and Service Channels are provided &ldquo;as is&rdquo;. Subject to applicable laws, G-Chime makes no warranties, representations, statements or guarantees (whether express, implied in law or residual) in this regard. Subject to applicable laws, G-Chime do not warrant that the Service, the Service Channels and the information they contain, or any output generated by your use of the Service, or any alert, will:
</p>
<ul  class="ul1" >
    <li>meet your requirements or expectations;</li>
    <li>be uninterrupted, timely, secure or error free;</li>
    <li>meet any particular measure of accuracy, completeness or reliability, performance or quality; or</li>
    <li>be free of viruses or any other data or code which has the ability to corrupt or adversely affect the operation of your computer, data or network.</li>
</ul >
<p class="p1">
    No information obtained by you from G-Chime through or from the Service will create any warranty by G-Chime. You warrant that -
</p>
<ul  class="ul1" >
    <li>you are legally entitled to access the Service Channels and use the Service;</li>
    <li>you have, and will continue to have for the duration of the Terms, all necessary consents to use the Service;</li>
    <li>you have capacity to be bound by the Terms.</li>
</ul >

<br/><p class="p1"><strong>LIABILITY</strong></p><br/>

<p class="p1">
    We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
</p>
<p class="p1">
    Subject to the above, neither G-Chime nor its group companies or their respective directors will be liable for any liability or damage of any nature which may be caused by or attributable, directly or indirectly, to:
</p>
<ul  class="ul1" >
    <li>your use of or reliance on the Service;</li>
    <li>the Service Channels;</li>
    <li>your use of or reliance on any information offered on or via the Service Channels;</li>
    <li>your acts or omissions or those of other users;</li>
    <li>a breach by you of the Terms;</li>
    <li>any unlawful access or monitoring of information transmitted to G-Chime; or</li>
    <li>any error or omission in respect of information submitted to G-Chime by you or other users.</li>
</ul >
<p class="p1">
    You will not be liable for any liability or damage (other than arising from your fraudulent actions or gross negligence) of any nature which may be caused by or attributable, directly or indirectly, to:
</p>
<ul  class="ul1" >
    <li>G-Chime's acts or omissions; or</li>
    <li>a breach by G-Chime of the Terms.</li>
</ul >
<p class="p1">
    Neither of us will be liable for any indirect or consequential loss or damage of whatever nature and however it may arise.
</p>

<br/><p class="p1"><strong>INDEMNITY</strong></p><br/>

<p class="p1">
    You indemnify G-Chime and its affiliates against any third party claims, damages or costs (including reasonable attorney's fees) caused by or attributable to your:
</p>
<ul  class="ul1" >
    <li>acts or omissions;</li>
    <li>use of the Service and the Service Channels;</li>
    <li>breach of these Terms.</li>
</ul >


<br/><p class="p1"><strong>SOLE AGREEMENT</strong></p><br/>

<p class="p1">
    These Terms are the sole record of the agreement between you and G-Chime in relation to the Service. Neither of us is bound by any express, tacit, or implied representation or warranty not recorded in these Terms. If either one of us gives the other an indulgence or extension of time, doing so will not waive or limit any of our rights, unless we expressly agree in writing to waive or limit them.
</p>

<br/><p class="p1"><strong>ASSIGNMENT</strong></p><br/>

<p class="p1">
    If G-Chime merges, sells, or otherwise assigns its assets and this involves transferring its rights and obligations under these Terms to a third party, G-Chime will let you know prior to this occurring. If you do not agree to the transfer of G-Chime's rights and obligations, you may terminate your use of the Service with effect from the date of transfer or assignment.
</p>

<br/><p class="p1"><strong>SEVERABILITY</strong></p><br/>

<p class="p1">
    If any provision of these Terms is or becomes unenforceable in any jurisdiction, the unenforceable aspect of that provision will be treated in such jurisdiction as if it had not been included in the Terms. The remaining provisions of the Terms will remain valid.
</p>

<br/><p class="p1"><strong>MINORS</strong></p><br/>

<p class="p1">If you are under 18, you may not use the Service.</p>
<br/><p class="p1"><strong>FORCE MAJEURE</strong></p><br/>

<p class="p1">
    If events or circumstances beyond our reasonable control ("force majeure") prevent either of us ("Affected Party") from fulfilling any of our obligations, those obligations will be suspended to the extent that and for as long as the Affected Party is so prevented. If the force majeure continues for more than fourteen days, the Affected Party can terminate the Terms by giving notice to the other. Force majeure includes an unavoidable natural catastrophe ("vis major"), an uncontrollable accident ("casus fortuitus"), any act of nature, strike, theft, riots, explosion, insurrection, war (whether declared or not), military operations, the downtime of any external telecommunications line, power failure, international restrictions, any requirement of any international authority, any requirement of any government or other competent local authority or any court order.
</p>

<br/><p class="p1"><strong>QUERIES AND COMPLAINTS</strong></p><br/>

<p class="p1">Please email any queries or complaints to G-Chime&apos;s customer support team at support@crimechime.co.za.</p>
<br/><p class="p1"><strong>APPLICABLE LAW</strong></p><br/>

<p class="p1">
    If there is any conflict between these Terms and Conditions and applicable laws, the applicable laws will apply. These Terms are governed by and interpreted in accordance with the laws of the United Kingdom without giving effect to any principles of conflict of law. You consent to the exclusive jurisdiction of the courts of the United Kingdom over any dispute about the Services or the Terms.
</p>

<br/><p class="p1"><strong>DATE OF PUBLICATION</strong></p><br/>

<p class="p1">
    A certificate signed by G-Chime's Directors will, unless the contrary is proven, be sufficient evidence of:
    <ul  class="ul1" >
        <li>the date of publication and the content of the Terms and new Terms;</li>
        <li>the date of publication and the content of earlier versions of the Terms; and</li>
        <li>the date and content of any communication and notifications sent in terms of the Terms.</li>
    </ul >
</p>

            </>
            </Typography>
            </Box>
    </div>
  );
};

export default Tnc;

